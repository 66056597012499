import { useState, useEffect, useRef } from "react";
import "./App.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import Cookies from "js-cookie";

const GOOGLE_MAPS_API_KEY = "AIzaSyC5mq7lvqgjdvHtrzl6dK-zjA_Oa3qtdDQ";

const customIcon = (size = [20, 30]) => new L.Icon({
  iconUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  iconSize: size,
  iconAnchor: [15, 41],
  popupAnchor: [0, -41]
});

function loadGoogleMapsScript(callback) {
  if (window.google && window.google.maps) {
    callback();
    return;
  }
  const script = document.createElement("script");
  script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
  script.async = true;
  script.defer = true;
  script.onload = callback;
  script.onerror = () => console.error("Google Maps API script failed to load.");
  document.body.appendChild(script);
}

function TimeCard({ city, offset, onRemove, isHighlighted, onHover }) {
  const localTime = new Date().toLocaleString("en-US", {
    timeZone: city.timezoneId,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    weekday: "long",
    month: "long",
    day: "2-digit",
    year: "numeric"
  });
  return (
    <div className={`time-card ${isHighlighted ? "highlight" : ""}`}
      onMouseEnter={() => onHover(city.name)}
      onMouseLeave={() => onHover(null)}>
      <div className="time-card-content">
        <span className="city-name">{city.name}</span>
        <span className="city-time">{localTime}</span>
      </div>
      <button className="remove-button" onClick={() => onRemove(city.name)}>✕</button>
    </div>
  );
}

// function Menu() {
//   const [isOpen, setIsOpen] = useState(false);

//   return (
//     <div>
//       <button onClick={() => setIsOpen(!isOpen)} style={{ position: "absolute", top: "10px", left: "10px", zIndex: 1000, background: "none", border: "none", padding: "0" }}>
//       <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
//         <path d="M4 18L20 18" stroke="#000000" stroke-width="2" stroke-linecap="round"/>
//         <path d="M4 12L20 12" stroke="#000000" stroke-width="2" stroke-linecap="round"/>
//         <path d="M4 6L20 6" stroke="#000000" stroke-width="2" stroke-linecap="round"/>
//       </svg>
//       </button>
//       {isOpen && (
//         <div style={{
//           position: "fixed",
//           top: 0,
//           left: 0,
//           width: "25%",
//           height: "100%",
//           backgroundColor: "white",
//           boxShadow: "2px 0px 5px rgba(0,0,0,0.3)",
//           zIndex: 999,
//           padding: "20px"
//         }}>
//           {/* <button onClick={() => setIsOpen(false)} style={{ marginBottom: "10px" }}>✕</button> */}
//           <p>Menu Content</p>
//         </div>
//       )}
//     </div>
//   );
// }

function App() {
  const [selectedCities, setSelectedCities] = useState([]);
  const [highlightedCity, setHighlightedCity] = useState(null);
  const autocompleteRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    document.title = "World Time";
    
    const savedCities = Cookies.get("selectedCities");
    if (savedCities) {
      try {
        const parsedCities = JSON.parse(savedCities);
        if (Array.isArray(parsedCities)) {
          setSelectedCities(parsedCities);
        }
      } catch (error) {
        console.error("Failed to parse cities from cookies:", error);
      }
    }
    
    const interval = setInterval(() => {
      if (window.google && window.google.maps && window.google.maps.places) {
        clearInterval(interval);
        loadGoogleMapsScript(() => {
          autocompleteRef.current = new window.google.maps.places.Autocomplete(
            inputRef.current,
            { types: ["(cities)"] }
          );
          autocompleteRef.current.addListener("place_changed", handlePlaceSelect);
        });
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (selectedCities.length > 0) {
      Cookies.set("selectedCities", JSON.stringify(selectedCities), { expires: 7 });
    }
  }, [selectedCities]);

  async function handlePlaceSelect() {
    if (!autocompleteRef.current) return;
    const place = autocompleteRef.current.getPlace();
    if (!place || !place.geometry) return;
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    if (lat === undefined || lng === undefined) return;
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${Math.floor(Date.now() / 1000)}&key=${GOOGLE_MAPS_API_KEY}`
    );
    const data = await response.json();
    if (data.status === "OK") {
      const newCity = {
        name: place.formatted_address,
        timezoneId: data.timeZoneId,
        lat: lat,
        lng: lng
      };
      setSelectedCities((prevCities) => [...prevCities, newCity]);
    }
    inputRef.current.value = "";
  }

  const handleRemoveCity = (cityName) => {
    setSelectedCities(selectedCities.filter(city => city.name !== cityName));
  };

  return (
    <div className="container">
      {/* <Menu /> */}
      <div className="left-panel" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <h1 className="title">World Time</h1>
        <input type="text" ref={inputRef} placeholder="Type city name..." />
        <div className="city-list">
          {selectedCities.map((city) => (
            <TimeCard 
              key={city.name} 
              city={city} 
              offset={city.offset} 
              onRemove={handleRemoveCity} 
              isHighlighted={highlightedCity === city.name}
              onHover={setHighlightedCity}
            />
          ))}
        </div>
        <footer className="footer" style={{ marginTop: 'auto', textAlign: 'center', padding: '10px' }}>© 2025 alluseful.tools</footer>
      </div>
      <div className="right-panel">
        <MapContainer center={[20, 0]} zoom={2} style={{ height: "calc(100% - 30px)", width: "100%" }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&copy; OpenStreetMap contributors"
          />
          {selectedCities.filter(city => city.lat !== undefined && city.lng !== undefined).map((city) => (
            <Marker 
              key={city.name} 
              position={[city.lat, city.lng]} 
              icon={highlightedCity === city.name ? customIcon([25, 35]) : customIcon([20, 30])} 
              eventHandlers={{
                mouseover: () => setHighlightedCity(city.name),
                mouseout: () => setHighlightedCity(null)
              }}
            >
              <Popup>{city.name}</Popup>
            </Marker>
          ))}
        </MapContainer>
        
      </div>
    </div>
  );
}

export default App;
